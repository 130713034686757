

h4{
  font-family: "GloriaHallelujah";
  font-size: 36px;
  margin: 5% 5%;
}

.chatLogin{
  margin-top:100px;
  width: 100%;
}
  input{
      width: 90%;
      font-size: 18px;
      border: none;
      outline: none;
      border-bottom: 1px solid #8e8e8e;
      background: #efefef;
      padding: 2px 2px 4px 2px;
      margin-left: 5%;
      margin-bottom: 18px;
  }

 .chatButton{
    font-family: "Poppins", Arial, Helvetica, sans-serif;
    color: #fff;
    letter-spacing: 0.05em;
    text-align: center;
    padding: 15px 0;
    margin: 34px 10% 0 10%;
    width: 80%;
    background: #bc1aed;
    border: 0;
    border-radius: 24px;
    cursor: pointer;
  }



.App {
  width: 100vw;
  height: 100vh;
  background: #fff;
  color: #212121;

  display: grid;
  place-items: center;
}

.joinChatContainer {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.joinChatContainer h3 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
}
.joinChatContainer input {
  width: 210px;
  height: 40px;
  margin: 7px;
  border: 2px solid #43a047;
  border-radius: 5px;
  padding: 5px;
  font-size: 16px;
}

.joinChatContainer button {
  width: 225px;
  height: 50px;
  margin: 7px;
  border: none;
  border-radius: 5px;
  padding: 5px;
  font-size: 16px;
  background: #43a047;
  color: #fff;
  cursor: pointer;
}

.joinChatContainer button:hover {
  background: #2e7d32;
}

.chat-window {
  position: fixed;
  bottom: 58px;
  width: 100%;
  /* height: 420px; */
}

.chat-window p {
  margin: 0;
}
.chat-window .chat-header {
  height: 45px;
  border-radius: 6px;
  background: #263238;
  position: relative;
  cursor: pointer;
}
.chat-window .chat-header p {
  display: block;
  padding: 0 1em 0 2em;
  color: #fff;
  font-weight: 700;
  line-height: 45px;
}

.chat-window .chat-body {
  /* height: calc(450px - (45px + 70px)); */
  height: 600px;
  width: 100%;
  /* border: 1px solid #263238; */
  /* background: #fff; */

  position: relative;
}
.chat-window .chat-body .message-container {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
}

.chat-window .chat-body .message-container::-webkit-scrollbar {
  display: none;
}
.chat-window .chat-body .message {
  height: auto;
  padding: 10px;
  display: flex;
}

.chat-window .chat-body .message .message-content {
  width: 90%;
  /* min-height: 40px;
  max-width: 80%; */
  background-color: #711bfa;
  border-radius: 12px;
  color: white;
  display: flex;
  align-items: center;
  margin-right: 5px;
  margin-left: 5px;
  padding: 8px 12px;
  overflow-wrap: break-word;
  word-break: break-word;
}

#you {
  justify-content: flex-start;
}

#you .message-content {
  justify-content: flex-start;
}

#you .message-meta {
  justify-content: flex-start;
  margin-left: 5px;
}

#other {
  justify-content: flex-end;
}

#other .message-content {
  justify-content: flex-end;
  background-color: rgb(224, 68, 255); 

}

#other .message-meta {
  justify-content: flex-end;
  margin-right: 5px;
}

.message-meta #author {
  margin-left: 10px;
  font-weight: bold;
}

.chat-window .chat-body .message .message-meta {
  display: flex;
  gap: 24px;
  font-size: 12px;
}

.chat-window .chat-footer {
  height: 75px;
  background: #fff;
 
  padding: 1rem 1rem 2rem 1rem;
  display: flex;
  gap: 20px;
}

.chat-window .chat-footer input {
  background: #d6d6d6;
  flex: 80%;
  border: 0;
  border-radius: 24px;
  padding: 24px 18px;
  font-size: 1rem;
  outline: none;
}

.chat-window .chat-footer button {
  border: 0;
  display: grid;
  place-items: center;
  cursor: pointer;
  flex: 20%;
  padding: 11px 19px 39px 25px;
  align-items: center;
  border-radius: 20px;
  /* height: 100%; */
  background: #ff5870;
  outline: none;
  font-size: 25px;
  color: #fff;
}

.chat-window .chat-footer button:hover {
  color: #9900ff;
}
.hide {
  opacity: 0 !important;
}