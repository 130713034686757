@font-face {
    font-family: "Poppins";
    src: url(assets/fonts/Poppins/Poppins-Regular.ttf);
}

@font-face {
    font-family: "PoppinsBold";
    src: url(assets/fonts/Poppins/Poppins-Bold.ttf);
}

@font-face {
    font-family: "GloriaHallelujah";
    src: url(assets/fonts/GloriaHallelujah-Regular.ttf);
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: "Poppins", Arial, Helvetica, sans-serif;
    background: #efefef;
    color: #303030;
}

#root>div {
    width: 390px;
    height: 844px;
}

a {
    text-decoration: none;
}

img {
    width: 100%;
}

h2 {
    font-family: "Poppins", Arial, Helvetica, sans-serif;
    font-size: 33px;
    color: #303030;
    text-align: center;
}

h3 {
    font-family: "Poppins", Arial, Helvetica, sans-serif;
    font-size: 24px;
    color: #000;
}

.loadingIndicator {
    height: 100vh;
    overflow: hidden;

    img {
        margin-top: 50%;
    }
}

.errorText {
    width: 90%;
    color: tomato;
    font-size: 18px;
    text-align: center;
    margin: 36px 5% 0px 5%;
    padding: 24px 52px;
    background: #fff;
    border: 1px solid #666;
    border-radius: 8px;
}

.successText {
    position: absolute;
    top: 30%;
    width: 90%;
    color: #efefef;
    font-size: 18px;
    text-align: center;
    margin: 36px 5% 0px 5%;
    padding: 24px 52px;
    background: rgb(112, 146, 84);
    border: 1px solid #666;
    box-shadow: 2px 2px 8px 3px #8b8b8b;
    border-radius: 8px;
    z-index: 2;
}


.killDog {
    color: tomato !important;
}


// ================================================
// SPLASH
// ================================================
.splash {
    background-image: linear-gradient(0deg, #FF5870, #5A1AED);
    height: 100vh;

    .dd-logo-wrapper {
        display: flex;
        padding-top: 30%;
        justify-content: center;
    }

    .dd-logo {
        display: flex;
        width: 144px;
        background: #fff;
        border: 10px solid #fff;
        border-radius: 50%;
    }

    h1 {
        font-size: 50px;
        color: #fff;
        font-weight: 100;
        text-align: center;
        margin-top: 0.5rem;
        margin-bottom: 6rem;
    }

    button {
        display: flex;
        width: 86%;
        letter-spacing: 0.05em;
        margin: 5% 7%;
        position: relative;
        padding: 0.8rem;
        background: #fff;
        border: 1px solid #fff;
        border-radius: 22px;
        cursor: pointer;

        .wbLogo {
            position: absolute;
            left: 18px;
            top: 3px;
            width: 35px;
        }

        .wbLogoAT {
            position: absolute;
            left: 22px;
            top: 7px;
            width: 27px;
        }

        .wbText {
            color: #373737;
            width: 100%;
            text-align: center;
            top: 5px;
        }
    }

    .wText {
        width: 70%;
        color: #fff;
        font-size: 0.7rem;
        line-height: 19px;
        text-align: center;
        margin: 0 auto;

        a {
            font-family: "PoppinsBold";
            color: #fff;
            text-decoration: underline;
        }

        a:hover {
            text-decoration: none;
        }
    }

    .signUpfooter {
        position: absolute;
        bottom: 2rem;
        width: 100%;

        p {
            color: #fff;
            font-size: 0.8rem;
            text-align: center;

            a {
                font-family: "PoppinsBold";
                color: #fff;
                text-decoration: none;
            }

            a:hover {
                text-decoration: underline;
            }
        }
    }

    .splashFooter {
        position: fixed;
        bottom: 2px;
        width: 100%;
        margin: 0 auto;
        font-size: 10px;
        color: #fff;
        display: flex;
        justify-content: space-evenly;

        .iconDog {
            width: 11px;
        }

        .iconVegan {
            width: 8px;
        }
    }
}



// ================================================
// PROFILE -- SignUp -- LogIn
// ================================================
.profile {

    .profile-header {
        width: 100%;
        position: fixed;
        top: 0;
        padding-top: 50px;
        padding-bottom: 6px;
        background: #fff;
        text-align: center;
        z-index: 2;
        border-bottom: 2px solid #efefef;

        .profile-arrow-back {
            position: fixed;
            top: 60px;
            left: 16px;
            width: 26px;
            cursor: pointer;
        }

        .profile-header-info {
            display: flex;
            margin-left: 100px;

            img {
                border-radius: 50%;
                width: 60px;
                height: 60px;
                margin-right: 10px
            }
        }

    }

    //  SIGNUP PAGE
    // ---------------------------------
    .signup-box {
        width: 90%;
        padding-top: 120px;
        // Frank geändert: Ursprünglich => 0px 5% 0px 5%;
        margin: 0px 5% 0px 3%;

        input,
        select {
            width: 100%;
            border: none;
            // border-bottom: 1px solid #8e8e8e;
            background: #efefef;
            padding: 2px 2px 4px 2px;
            // font-family: "Poppins", Arial, Helvetica, sans-serif;
            margin-bottom: 12px;
        }

        input:focus,
        select:focus {
            border: none;
            border: 0;
            outline: none;
            // border-bottom: 1px solid #555;
        }

        option {
            font-family: "Poppins", Arial, Helvetica, sans-serif;
        }

        // button {
        //     font-family: "Poppins", Arial, Helvetica, sans-serif;
        //     color: #fff;
        //     letter-spacing: 0.05em;
        //     text-align: center;
        //     padding: 15px 0;
        //     margin: 34px 10% 0 10%;
        //     width: 80%;
        //     background: #5A1AED;
        //     border: 0;
        //     border-radius: 24px;
        //     cursor: pointer;
        // }

        .saveButton {
            margin-bottom: 120px;
            letter-spacing: 0.05em;
        }

        .customButton-save {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 2vh;
        }


        // Frank-----------------------------------
        #image_uploads {
            opacity: 0;
        }

        .mainImageUpload {
            img {
                height: 300px;
            }
        }

        .dataFrameSlider {
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            border: 1px solid rgba(0, 0, 0, 0.245);
            width: 100%;
            padding: 10px 15px;
            margin: 10px 0;
        }

        // Frank-----------------------------------
    }


    //  PROFILE PAGE :: Edit Avatar
    // ---------------------------------
    .editAvatarBody {
        margin: 130px 5% 0 5%;
    }

    //  PROFILE PAGE
    // ---------------------------------

    .profileBody {
        width: 90%;
        // Frank geändert: Ursprünglich => 0px 5% 0px 5%;
        margin: 0 5% 100px 3%;

        .profilePic {
            position: relative;
            width: 50%;
            margin: 114px 25% 0 25%;
            border-radius: 50%;

            img {
                border-radius: 50%;
            }

            .editProfilepic {
                position: absolute;
                bottom: 3px;
                right: 3px;
                width: 36px;
                height: 36px;
                background: #fff;
                border-radius: 50%;
                padding: 6px;
                box-shadow: 0 0 4px #666;
                cursor: pointer;
            }
        }

        .headlineFrame {
            display: flex;
            justify-content: space-between;
            align-items: center;
            // Frank geändert: Ursprünglich => 100%;
            width: 98%;
            padding: 8px 0px;
            margin: 8px 0px;

            h2 {
                width: 100%;
                justify-content: flex-start;

            }

            .headlineButton {
                font-size: 16px;
                display: flex;
                justify-content: flex-end;
                color: #ff5870;
                border: 0;
                cursor: pointer;
            }
        }

        .dataFrameSlider {
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            border: 1px solid #dbdbdb;
            width: 100%;
            padding: 8px 8px;
            margin: 8px 0;
        }

        .dataFrame {
            display: flex;
            justify-content: space-around;
            border: 1px solid #dbdbdb;
            width: 100%;
            padding: 8px 8px;
            margin: 8px 0;

            .dataLable {
                width: 70%;
                font-size: 14px;
                display: flex;
                justify-content: flex-start;
                color: #2d2d2d;
                // border: 1px solid red;
            }

            .dataData {
                width: 100%;
                font-size: 16px;
                display: flex;
                flex-wrap: no-wrap;
                justify-content: flex-end;
                color: #413f3f;
            }

            .dataButton {
                font-size: 16px;
                display: flex;
                justify-content: flex-end;
                color: #ff5870;
                border: 0;
                cursor: pointer;
            }

            .colorHighlight {
                color: #ff5870;
            }

            .colorHighlightGreen {
                color: green;
            }


        }

        .buttonLogout {
            width: 100%;
            margin: 36px 0 0 0;
            background: #fff;
            font-size: 14px;
            letter-spacing: 0.05em;
            color: #ff5870;
            text-align: center;
            padding: 15px 0;
            border: 1px solid #ccc;
            border-radius: 24px;
            cursor: pointer;
        }

        .buttonDeleteAccount {
            width: 100%;
            font-family: "Poppins";
            font-weight: 100;
            letter-spacing: 0.05em;
            text-transform: none;
            margin: 12px 0;
            background: #5A1AED;
            font-size: 14px;
            color: #fff;
            text-align: center;
            padding: 15px 0;
            border: 0;
            border-radius: 24px;
            cursor: pointer;
            margin-bottom: 72px;
        }
    }
}

// ================================================
// HOME -- SWIPE like/unlike 
// ================================================
.css-hogos2 {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-bottom-left-radius: 22px;
    border-bottom-right-radius: 22px;
    outline: none;
}

.dataFrame {
    padding: 12px 0 12px 0;

    .rangeHL {
        margin: 0px 0 32px 0;
    }

    .optionBox {
        display: flex;
        width: 100%;


        // G e n d e r B o x  ------------------------------
        .genderLeft {
            width: 50%;
            border: 1px solid #CACACA;
            border-top-left-radius: 12px;
            border-bottom-left-radius: 12px;
            text-align: center;
            color: #CACACA;
            padding: 4px 0;
        }

        .genderLeft:hover,
        .genderLeft-aktiv {
            border: 1px solid #CACACA;
            text-align: center;
            color: #fff;
            background: #1769aa;
        }

        .genderRight {
            width: 50%;
            border: 1px solid #CACACA;
            color: #CACACA;
            border-top-right-radius: 12px;
            border-bottom-right-radius: 12px;
            text-align: center;
            padding: 4px 0;
        }

        .genderRight:hover,
        .genderRight-aktiv {
            border: 1px solid #CACACA;
            color: #fff;
            background: #1769aa;
            text-align: center;
        }

        // S i z e B o x  ------------------------------
        .sizeSmall {
            width: 33%;
            border: 1px solid #CACACA;
            border-top-left-radius: 12px;
            border-bottom-left-radius: 12px;
            font-family: "PoppinsBold";
            text-align: center;
            color: #CACACA;
            padding: 4px 0;
        }

        .sizeSmall:hover,
        .sizeSmall-aktiv {
            border-top-left-radius: 12px;
            border-bottom-left-radius: 12px;
            color: #fff;
            background: #1769aa;
        }

        .sizeMiddle {
            width: 34%;
            border: 1px solid #CACACA;
            font-family: "PoppinsBold";
            color: #CACACA;
            text-align: center;
            padding: 4px 0;
        }

        .sizeMiddle:hover,
        .sizeMiddle-aktiv {
            color: #fff;
            background: #1769aa;
        }

        .sizeLarge {
            width: 33%;
            border: 1px solid #CACACA;
            font-family: "PoppinsBold";
            color: #CACACA;
            border-top-right-radius: 12px;
            border-bottom-right-radius: 12px;
            text-align: center;
            padding: 4px 0;
        }

        .sizeLarge:hover,
        .sizeLarge-aktiv {
            width: 33%;
            border: 1px solid #CACACA;
            color: #fff;
            background: #1769aa;
            border-top-right-radius: 12px;
            border-bottom-right-radius: 12px;
            text-align: center;
        }

    }
}

.home {
    // background: #f7f7f7;  
    background-image: linear-gradient(0deg, #e9e9e9, #f7f7f7);
    height: 100vh;

    .home-header {
        position: absolute;
        top: 54px;
        width: 100%;
        display: flex;

        .home-dd-logo {
            width: 80px;
            padding: 0 12px 0 18px;
        }

        h2 {
            font-size: 30px;
            color: #ff5870;
        }

        .home-filter {
            position: absolute;
            right: 12px;
            width: 28px;
            padding-top: 11px;
            cursor: pointer;
        }
    }


    // D i e   S W I P E - B o x ------------------------------
    .home-doggy-bigpic {
        position: relative;
        top: 120px;
        width: 351px;
        margin: 0% 19px;
        line-height: 0;
        border-radius: 28px;
        box-shadow: 0px 4px 8px 4px #ccc;
        overflow: hidden;

        img {
            width: 351px;
            height: 540px;
            border-radius: 28px;
        }

        .dog-wrapperBackground {
            position: absolute;
            top: 0px;
            left: 0px;
            width: 100%;

            line-height: 0;
            z-index: 1;

            .textL1 {
                font-family: "GloriaHallelujah";
                position: absolute;
                background: coral;
                bottom: 75px;
                width: 100%;
                padding: 0 12px;
                text-align: center;
                font-size: 24px;
                color: #333;
                text-shadow: 0 0 2px #666;
            }

            .textL2 {
                font-family: "GloriaHallelujah";
                position: absolute;
                background: coral;
                bottom: 45px;
                width: 100%;
                padding: 0 12px;
                text-align: center;
                font-size: 24px;
                color: #333;
                text-shadow: 0 0 2px #666;
            }
        }

        .dog-wrapper00 {
            position: absolute;
            top: 0px;
            left: 0px;
            width: 100%;
            // height: 120px;
            line-height: 0;

            // .dogName {
            //     position: absolute;
            //     bottom: 50px;
            //     left: 16px;
            //     font-size: 36px;
            //     color: #fff;
            //     text-shadow: 0 0 1px #333;
            // }

            // .distanceKM {
            //     position: absolute;
            //     bottom: 24px;
            //     left: 16px;
            //     font-size: 18px;
            //     color: #fff;
            //     text-shadow: 0 0 1px #333;
            // }
        }

        .dog-wrapper01 {
            position: absolute;
            top: 0px;
            width: 100%;
            line-height: 0;
        }

        // .dogName {
        //     position: absolute;
        //     bottom: 50px;
        //     left: 16px;
        //     font-size: 36px;
        //     color: #fff;
        //     text-shadow: 0 0 1px #333;
        // }

        // .distanceKM {
        //     position: absolute;
        //     bottom: 24px;
        //     left: 16px;
        //     font-size: 18px;
        //     color: #fff;
        //     text-shadow: 0 0 1px #333;
        // }


        // .dog-wrapper03 {
        //     position: absolute;
        //     top: 0px;
        //     width: 100%;
        //     line-height: 0;
        //     // z-index: 13;
        //     animation-name: slideLeft;
        //     animation-duration: 2s;
        //     animation-delay: 6s;
        //     animation-iteration-count: infinite;
        //     transition-timing-function: ease-in;

        //     .dogName {
        //         position: absolute;
        //         bottom: 50px;
        //         left: 16px;
        //         font-size: 36px;
        //         color: #fff;
        //         text-shadow: 0 0 2px #333;
        //     }

        //     .distanceKM {
        //         position: absolute;
        //         bottom: 24px;
        //         left: 16px;
        //         font-size: 18px;
        //         color: #fff;
        //         text-shadow: 0 0 2px #333;
        //     }
        // }

        // .dog-wrapper05 {
        //     position: absolute;
        //     top: 0px;
        //     width: 100%;
        //     line-height: 0;
        //     animation-name: slideRight;
        //     animation-duration: 10s;
        //     animation-delay: 2s;
        //     animation-iteration-count: infinite;
        //     transition-timing-function: ease-in;

        //     .dogName {
        //         position: absolute;
        //         bottom: 50px;
        //         left: 16px;
        //         font-size: 36px;
        //         color: #fff;
        //         text-shadow: 0 0 1px #333;
        //     }

        //     .distanceKM {
        //         position: absolute;
        //         bottom: 24px;
        //         left: 16px;
        //         font-size: 18px;
        //         color: #fff;
        //         text-shadow: 0 0 1px #333;
        //     }
        // }

        @keyframes slideRight {

            0% {
                transform: translate(0px, 0px)
            }

            25% {
                transform: translate(360px, 0px)
            }

            100% {
                transform: translate(360px, 0px)
            }
        }

        @keyframes slideLeft {
            0% {
                transform: translate(0px, 0px)
            }

            100% {
                transform: translate(-360px, 0px)
            }
        }
    }

    .home-like-wrapper {
        position: absolute;
        bottom: 85px;
        left: 25%;
        right: 25%;
        width: 50%;

        .home-like-buttons {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 20%;
            justify-items: center;
            justify-content: center;
            align-content: center;
            cursor: pointer;

            .home-dislike {
                width: 66px;
                height: 66px;
                background-image: linear-gradient(0deg, #FF5870, #5A1AED);
                border-radius: 50%;
                padding: 17px;
            }

            .home-like {
                width: 66px;
                height: 66px;
                background-image: linear-gradient(0deg, #FF5870, #5A1AED);
                border-radius: 50%;
                padding: 15px;
            }

            .home-dislike img {
                width: 32px;
                height: 32px;
            }

            .home-like img {
                margin-top: 2px;
            }
        }
    }
}


// ------------------------------------------------------------
//  D i e   L I K E - S E I T E  ------------------------------
// ------------------------------------------------------------


.likeList {
    margin-top: 100px;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 32px;
    padding: 0 24px;

    .likeCard {
        box-shadow: 1px 1px 5px 3px #ccc;
        border-radius: 12px;
        line-height: 0;

        img {
            border-radius: 12px;
        }

        .likeDogname {
            position: relative;
            bottom: 12px;
            left: 12px;
            color: #fff;
        }
    }

}


.matchMatch {
    background-image: linear-gradient(0deg, #FF5870, #5A1AED);
    height: 100vh;
    color: #fff;

    h1 {
        font-family: "PoppinsBold";
        color: #fff;
        font-size: 42px;
        text-align: center;
        padding-top: 200px;
    }

    p {
        text-align: center;
        font-size: 18px;
    }

    .loveBox {
        margin-top: 34px;
        display: flex;
        justify-content: center;

        .likeDogleft {
            width: 160px;
            position: relative;
            left: 8px;

            img {
                border-radius: 50%;
            }
        }

        .likeDogright {
            width: 160px;
            position: relative;
            right: 8px;
            z-index: 2;

            img {
                border-radius: 50%;
                border: 4px solid #ff04f2;
            }
        }
    }

    .buttonMatchChat {
        background: #fff;
        color: #FF5870;
        letter-spacing: 0.05em;
        text-align: center;
        width: 90%;
        padding: 12px;
        margin: 10% 5% 5% 5%;
        border-radius: 22px;
        cursor: pointer;
    }

    .buttonMatchSwipe {
        color: #fff;
        letter-spacing: 0.05em;
        text-align: center;
        width: 90%;
        border: 3px solid #fff;
        padding: 10px;
        margin: 5% 5% 10% 5%;
        border-radius: 22px;
        cursor: pointer;
    }


}


footer {
    position: absolute;
    position: fixed;
    bottom: 0px;
    width: 100%;
    height: 58px;
    background: #fff;
    border-top: 2px solid #efefef;

    .nav {
        width: 90%;
        margin: 0 auto;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        justify-items: center;
        justify-content: space-between;
        gap: 0.5rem;

        div {
            width: 42px;
            padding: 12px 8px;

            img {
                height: 34px;
                width: 34px;
            }
        }
    }
}

// ------------------------------------------------------------
//  D i e   CHAT - S E I T E  -- da muss Guido noch dran-------
// ------------------------------------------------------------

.chatList {
    margin-top: 150px;
    width: 100%;
    padding: 0 24px;

    li {
        list-style: none;
    }

    .chatList-Item {
        display: flex;

        img {
            border-radius: 50%;
            width: 60px;
            height: 60px;
            margin-right: 10px
        }

    }

}